/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import agent from "../../../agent";
import { API_STATUS } from "../../../constant";
import Alert from "../../../Utils/Alert";
import AppContext from "../../../Context";
import Sidebar from "../../reusables/Sidebar";
const AddEditAddress = (props) => {
  const { data, onClose } = props;
  const isEdit = Boolean(data.title);
  const [localities, setLocalities] = React.useState([]);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [locality, setLocality] = useState("");
  const { setLoading, handleRefresh, loading } = useContext(AppContext);
  useEffect(() => {
    let isActive = true;
    if (isEdit && isActive) {
      setTitle(data.title);
      setAddress(data.address);
      setLandmark(data.landmark);
      setLocality(data.locality?.status ? data.locality?._id : "");
    }
    return () => {
      isActive = false;
    };
  }, [data]);
  function getaAllLocalities() {
    const payload = {
      status: true,
    };
    agent.Localities.getAll(payload)
      .then((res) => {
        if (API_STATUS.SUCCESS_CODE.includes(res?.status)) {
          setLocalities(res.data);
        } else {
          setLocalities([]);
          Alert.showToastAlert("error", res?.message);
        }
      })
      .catch((err) => {
        Alert.showToastAlert("error", err.message);
      });
  }
  useEffect(() => {
    let isActive = true;
    if (isActive) {
      getaAllLocalities();
    }
    return () => {
      isActive = false;
    };
  }, []);
  const handleClose = () => {
    setTitle("");
    setAddress("");
    setLocality("");
    setLandmark("");
    onClose();
  };
  function SaveAddress() {
    let formIsComplete = true;
    if (title === " " || address === "" || locality === "" || landmark === "") {
      Alert.showToastAlert("warning", "Required fields cannot be empty");
      formIsComplete = false;
    }

    if (formIsComplete) {
      const payload = {
        title,
        address,
        locality,
        landmark,
      };
      if (!isEdit) {
        setLoading(true);

        agent.Address.add({ address: payload })
          .then((res) => {
            if (API_STATUS.SUCCESS_CODE.includes(res?.status)) {
              Alert.showToastAlert("success", res?.message);
              handleClose();
              handleRefresh();
              setLoading(false);
            } else {
              Alert.showToastAlert("error", res?.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            Alert.showToastAlert("error", err.message);
            handleClose();
            setLoading(false);
          });
      } else {
        setLoading(true);

        const payload = {
          address: {
            title,
            address,
            locality,
            landmark,
          },
          addressId: data._id,
        };
        agent.Address.update(payload)
          .then((res) => {
            if (API_STATUS.SUCCESS_CODE.includes(res?.status)) {
              Alert.showToastAlert("success", res?.message);
              handleClose();
              handleRefresh();
              setLoading(false);
            } else {
              Alert.showToastAlert("error", res?.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            Alert.showToastAlert("error", err.message);
            handleClose();
            setLoading(false);
          });
      }
    }
  }

  return (
    <Sidebar onClose={handleClose} isOpen={props.isOpen}>
      <h3>{isEdit ? "Edit" : "Add"} Address</h3>
      <p className="input_fields input_name">
        <label>
          Title<span className="required">*</span>
        </label>
        <input
          type="text"
          placeholder="Enter Title"
          onChange={({ target }) => {
            setTitle(target.value);
          }}
          value={title}
        />
      </p>
      <p className="input_fields input_name">
        <label>
          Locality<span className="required">*</span>
        </label>
        <select
          value={locality}
          onChange={({ target }) => {
            setLocality(target.value);
          }}
          className="form-control"
          name="status"
          id="status"
        >
          <option value="">Select Locality</option>
          {localities.map((item, index) => (
            <option value={item._id} key={index}>
              {item.name}
            </option>
          ))}
        </select>
      </p>

      <p className="input_fields input_name">
        <label>
          Address<span className="required">*</span>
        </label>
        <textarea
          rows="5"
          placeholder="Enter Address"
          value={address}
          onChange={({ target }) => {
            setAddress(target.value);
          }}
        ></textarea>
      </p>
      <p className="input_fields input_name">
        <label>
          Landmark<span className="required">*</span>
        </label>
        <input
          type="text"
          placeholder="Enter Landmark"
          onChange={({ target }) => {
            setLandmark(target.value);
          }}
          value={landmark}
        />
      </p>
      <button
        className="update_btn"
        onClick={() => (loading ? null : SaveAddress())}
      >
        {loading ? "Loading.." : "Save Address"}
      </button>
    </Sidebar>
  );
};

export default AddEditAddress;
